/* @import url("https://fonts.googleapis.com/css2?family=Comfortaa&display=swap");
@import url("secondaryfont.css"); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

/* @font-face {
  font-family: "Feather Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Feather Bold"),
    url("../fonts/feather_bold_by_typicalbro44_dee263c.woff") format("woff");
} */
/* 
body {
    font-family: 'Comfortaa', cursive;
} */

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px !important;
  }
}

body {
  font-family: 'Montserrat', sans-serif !important;
}

/* body {
    font-family: 'DINRoundPro'; 
    font-weight: 900; 
    font-style: normal;
} */

.sign-in.navbar.navbar-transparent .navbar-brand {
  color: #3a86ff;
  font-size: 48px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.navbar,
.brand,
a,
.td-name,
td,
button,
input,
select,
textarea {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  /* font-family: 'Comfortaa', cursive; */
  font-family: 'Montserrat', sans-serif !important;
  /* font-family: 'DINRoundPro';  */
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
}

.title,
.card-title,
.info-title,
.footer-brand,
.footer-big h5,
.footer-big h4,
.media .media-heading {
  font-family: 'Feather Bold';
}

.navbar-transparent .navbar-brand,
[class*='bg'] .navbar-brand {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=90);
  font-size: 24px;
  font-weight: 800;
}

.color-palette {
  color: #0a3049;
  color: #d73f2a;
  color: #f17f30;
  color: #f8bf48;
}

.navbar .navbar-brand img {
  width: auto;
  height: 36px;
}

.navbar-expand-lg {
  flex-flow: row wrap !important;
  justify-content: flex-start !important;
  width: 100%;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 !important;
}

.navbar .navbar-nav .nav-item .nav-link {
  color: #ffffff !important;
}

li.nav-item {
  text-align: center;
}

a.nav-link i {
  font-weight: 700;
  font-size: 26px;
  margin-right: 10px;
}

.bg-gray {
  background: #f4f6fc;
}

.bg-blue {
  background: #d73f2a;
}

.bg-yellow {
  background-color: #ffcb00 !important;
  color: #ffffff !important;
}

.bg-orange {
  background-color: #d73f2a !important;
  color: #ffffff;
}

.navbar .btn {
  margin: 14px 10px;
  font-size: 16px;
}

.navbar .navbar-nav .nav-item .nav-link {
  line-height: 1.6;
  margin: 0;
  padding: 20px 15px;
  opacity: 1;
  font-size: 16px;
  text-transform: initial;
}

.page-header .filter::after {
  background-color: rgb(0 0 0 / 8%) !important;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.title-brand {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  text-align: left;
  color: #ffffff;
  display: block;
  z-index: 9;
}

nav.fixed-top.navbar.navbar-expand-lg .container {
  max-width: 80%;
}

@media screen and (max-width: 991px) {
  .navbar-collapse ul {
    position: relative;
    z-index: 3;
    height: auto;
  }
}

.header-box {
  display: flex;
}

.header-text-box {
  color: #fff;
  background-color: #e74c3c;
  animation: bg-color 10s infinite;
  -webkit-animation: bg-color 10s infinite;
  padding: 150px 40px 100px;
}

@-webkit-keyframes bg-color {
  0% {
    background-color: #07244b;
  }
  20% {
    background-color: #54d2d2;
  }
  40% {
    background-color: #ffcb00;
  }
  60% {
    background-color: #f8aa4b;
  }
  80% {
    background-color: #ff6150;
  }
  100% {
    background-color: #a71a0b;
  }
}
@keyframes bg-color {
  0% {
    background-color: #07244b;
  }
  20% {
    background-color: #54d2d2;
  }
  40% {
    background-color: #ffcb00;
  }
  60% {
    background-color: #f8aa4b;
  }
  80% {
    background-color: #ff6150;
  }
  100% {
    background-color: #a71a0b;
  }
}

.page-header {
  background-color: #b2afab;
  background-position: center center;
  background-size: cover;
  min-height: 620px;
  max-height: 999px;
  overflow: initial;
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bJWEPZ {
  margin-left: 0px;
  margin-right: 0px;
}

.bJWEPZ {
  position: relative;
  max-width: 100%;
  text-align: center;
  margin-top: 40px;
}

.bJWEPZ input {
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  outline: none;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 9999px;
  border: none;
  width: 100%;
  font-weight: 500;
  transition-duration: 300ms;
}

.bJWEPZ input {
  padding-right: 12rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.bJWEPZ button {
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  width: 100%;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #facb33;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  margin-right: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 9999px;
  border: none;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  transition-duration: 300ms;
}

.bJWEPZ button {
  position: absolute;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 12rem;
  line-height: 1;
}

.m-l-a {
  margin-left: auto;
}

.btn-full-blue-violent a {
  color: #fff;
}

.btn-full-blue-violent a:hover {
  color: #d4944c;
}

.btn-full-red {
  background-color: #d73f2e !important;
  color: #ffffff !important;
  border-color: #d73f2e !important;
}

.navbar .navbar-collapse:after {
  background-color: #ffffff;
}

.myspace {
  width: 200px;
  left: -75px;
}

section.content-page {
  margin-top: 180px;
}

.modal-body {
  padding: 20px 50px;
  color: #66615b;
}

/* button style */

label {
  display: block;
  margin-bottom: 0.5rem;
  width: 100%;
}

.btn {
  text-transform: initial !important;
  font-size: 16px !important;
}

.btn-light-orange {
  background-color: #e2f6f7;
  color: #5bd3d2;
  border-color: #e2f6f7;
  transition: all 0.3s;
}

.btn-light-orange:hover {
  background-color: #5bd3d2;
  color: #ffffff;
  border-color: #5bd3d2;
}

.btn-full-orange {
  background-color: #ee5330;
  border-color: #ee5330;
}

.btn-full-blue-violent {
  background-color: #6a1f15 !important;
  color: #ffffff !important;
  border-color: #6a1f15 !important;
}

.btn-full-blue-dark-back {
  background-color: #0a3049 !important;
  color: #ffffff !important;
  border-color: #0a3049 !important;
}

.btn-round {
  border-radius: 30px !important;
}

.navbar .btn i {
  font-size: 18px;
  position: relative;
  top: 2px;
  font-weight: 700;
}

/* li.nav-item:hover {
    background: #e1e9ff;
} */

.navbar .navbar-nav .nav-item .nav-link:hover,
.navbar .navbar-nav .nav-item .nav-link.active {
  background: #ffe9e9;
  color: #d73f2a !important;
  display: block;
  margin: 0;
  height: 100%;
}

.navbar .navbar-nav .nav-item .nav-link i {
  margin: 10px;
}

.m-0 {
  margin: 0 !important;
  padding: 0 10px;
  background: transparent;
  border: none;
}

.m-0:hover,
.m-0:active {
  background: transparent !important;
  color: #07244b;
}
.m-0:focus {
  background: transparent !important;
  color: #fff;
}

.two-n-one:hover {
  background: #ffcb00 !important;
  border-color: #ffcb00 !important;
}

.form-control {
  display: block;
  width: 100%;
  height: 45px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #efefef !important;
  background-clip: padding-box;
  border: 1px solid #efefef !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.has-danger .form-control {
  background-color: #ffffff;
  border: 1px solid #f5593d !important;
  color: #f5593d;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation)
  > .custom-file:not(:last-child)
  .custom-file-label::after {
  border-top-right-radius: 26.5px;
  border-bottom-right-radius: 26.5px;
  border-top-left-radius: 26.5px;
  border-bottom-left-radius: 26.5px;
}

.input-group.mb-4.password,
.form-group.mb-4.password {
  position: relative;
}

.input-group.mb-4.password i,
.form-group.mb-4.password i {
  cursor: pointer;
  position: absolute;
  bottom: 16px;
  height: 16px;
  right: 24px;
  color: #8e8e8e;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 18px;
  z-index: 9;
}

ul.validation-check {
  list-style: none;
  padding: 0;
}

ul.validation-check li {
  display: inline-block;
  background: #e6e6e6;
  color: #828282;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 25px;
  margin: 0 10px;
}

ul.validation-check li.validation-true {
  display: inline-block;
  background: #edffdf;
  color: #51a90a;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 25px;
  margin: 0 5px;
}

.input-group.mb-4.password {
  margin-bottom: 10px !important;
}

.orange-link {
  color: #ff6150;
  font-weight: 600;
}

.blue-link {
  color: #3a86ff;
  font-weight: 600;
}

/* eof button style */

/* signup page style */
.freelance-section img {
  width: 100%;
}

.customer-section img {
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 2;
  opacity: 0.1;
}

.signup-card {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.freelance-section {
  /* position: relative; */
  width: 50%;
  display: inline-block;
  float: left;
}

.customer-section {
  /* position: relative; */
  width: 50%;
  display: inline-block;
}

.sign-desc {
  position: absolute;
  z-index: 9;
  bottom: 20%;
  padding: 40px;
  margin: 40px;
  width: 40%;
  color: #fff;
}

.sign-desc h2 {
  text-shadow: 2px 2px 4px #00000082;
}

.sign-desc a {
  box-sizing: border-box;
  display: inline-block;
  border-width: 2px;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
  padding: 0.5rem 18px;
  line-height: 1.75;
  cursor: pointer;
  /* text-transform: uppercase; */
  background-color: #66615b;
  border-color: #66615b;
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
  background-color: #ffcb00;
  color: #ffffff;
  border-color: #ffcb00;
  border-radius: 30px;
  transition: all 150ms linear;
}

.freelance-section .sign-desc a {
  background-color: #54d2d2;
}

.customer-section .sign-desc a {
  background-color: #ff6150;
}

.account-settings-button i {
  font-size: 28px;
}

.account-settings-button {
  border-left: 1px solid #ccc;
  height: 48px;
}

.dropdown-info {
  top: 90px;
}

.account-settings-info {
  left: -100px;
}

.modal-body.signin-modal {
  padding: 0;
}

.modal-body.signin-modal .col-md-6 {
  padding: 0;
}

.profile-item img {
  width: auto;
  height: 568px;
}

.profile-item {
  position: relative;
  height: 568px;
  overflow: hidden;
  margin-bottom: 0px;
  transition: all 0.3s;
}

.profile-item .profile-item-content {
  position: absolute;
  bottom: 10%;
  padding: 20px;
  color: #fff;
  text-shadow: 2px 2px 4px #00000082;
}

.signin-body {
  background: #fff;
  width: 50%;
  height: inherit;
  display: inline-block;
  float: left;
  padding: 150px 170px;
}

/* eof */

/* button action loader style */
.request-time {
  background-color: #403d39;
  color: #ffffff;
  border-color: #403d39;
  box-shadow: none;
}
.request-time:disabled,
.request-time[disabled],
.request-time.disabled {
  opacity: 1;
}
img.submit-loader {
  width: 30px;
  margin-left: 10px;
}
@keyframes stretch {
  0% {
    transform: scale(0.5);
    background-color: #ffffff;
  }
  50% {
    background-color: #ffffff;
  }
  100% {
    transform: scale(1);
    background-color: #ffffff;
  }
}

.btn.btn--loading {
  font-size: 0;
}
.btn.btn--loading span {
  display: flex;
  padding: 7px;
}
.btn.btn--loading span b {
  animation-direction: alternate;
  animation-duration: 0.5s;
  animation-fill-mode: none;
  animation-iteration-count: infinite;
  animation-name: stretch;
  animation-play-state: running;
  animation-timing-function: ease-out;
  border-radius: 100%;
  display: block;
  height: 15px;
  margin: 0 1px;
  width: 15px;
  animation-delay: 0.1s;
  margin: 0 5px;
}
.btn.btn--loading span b:first-child {
  animation-delay: 0s;
  margin: 0;
}
.btn.btn--loading span b:last-child {
  animation-delay: 0.2s;
  margin: 0;
}
.btn b {
  font-weight: normal;
}
.btn span {
  display: none;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #d73f2a !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  color: #fff;
}
.table-responsive {
  display: block;
  width: 100%;
  overflow: initial !important;
  -webkit-overflow-scrolling: touch;
}
.dropdown-menu .dropdown-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dropdown-menu .dropdown-item:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dropdown-menu.dropdown-info .dropdown-item:hover,
.dropdown-menu.dropdown-info .dropdown-item:focus {
  background-color: #3d3e41;
}

span.pending {
  background: #fffcf2;
  padding: 5px 10px;
  border-radius: 50px;
  color: #ffc107;
}
span.supply {
  background: #dbffe3;
  padding: 5px 10px;
  border-radius: 50px;
  color: #28a745;
}
span.cancel {
  background: #ffe9eb;
  padding: 5px 10px;
  border-radius: 50px;
  color: #dc3545;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

thead {
  background: #fbebe9;
  color: #d73f2a;
}

.order-settings-button img.submit-loader {
  width: 30px;
  margin-left: 10px;
  float: right;
}

.form-box {
  background: #f8bf48;
  display: block;
  padding: 20px;
  margin-bottom: 40px;
  border-radius: 10px;
}

.form-box h3,
.form-box label {
  color: #fff;
}

.form-box h3 {
  margin-bottom: 30px;
}

p.maj-notif {
  background: #0a3049;
  padding: 20px;
  color: #fff;
  display: block;
  min-height: 60px;
}

p.item_price_total {
  font-size: 28px;
  color: #d73f2a;
}

.calcul-box input {
  border-radius: 0px !important;
  margin: 0 20px;
}

.calcul-box {
  margin-top: 50px;
}

span.success-message {
  background: #58a57e;
  display: inline-block;
  width: 100%;
  padding: 10px;
  color: #fff;
  margin: 10px 0;
}

.box-container {
  background: #e8e8e8;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.ds-box {
  position: relative;
}

.ds-box a {
  text-decoration: none !important;
}

.ds-box .icon-box {
  padding: 20px;
  font-size: 58px;
}

.ds-box .title-box {
  background: #0000001f;
  padding: 10px;
}

.total p {
  font-size: 24px !important;
  color: #d73f2a !important;
}
/* eof */

.dg-notif {
  background: #f8bf48 !important;
}

li.notification-icon span {
  display: inline;
}

.bull {
  position: absolute;
  top: -30px;
  right: -12px;
  font-size: 11px !important;
  padding: 5px 10px !important;
}

li.notification-icon {
  position: relative;
}

li.notification-icon i {
  font-size: 20px !important;
  color: #fff;
}

li.notification-icon {
  background: #af3322;
}

.nav-tabs-navigation .nav > .nav-item > .nav-link {
  padding-bottom: 20px;
  cursor: pointer;
}

.nav-tabs {
  border-bottom: none !important;
}

input.form-control.small-field {
  width: 30% !important;
  margin: 0 auto;
}

.nav-tabs-navigation {
  text-align: left;
  border-bottom: 1px solid #f1eae0;
  margin-bottom: 30px;
}

.full-width {
  width: 100%;
}

.profile-box {
  position: relative;
}

.place-down {
  position: relative;
  width: 50%;
  top: 170px;
}

.place-down a {
  color: #000 !important;
}

.modal-body.signin-modal button.close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 38px;
  float: right;
  margin: 10px;
  z-index: 9;
}

.profile-body {
  padding: 20px 40px 20px 30px;
}

div#search_wrapper {
  background: #fff;
  padding: 30px 20px;
  border-radius: 20px;
  color: #000;
}

input#advanced_submit_22 {
  background: #d73f2a;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 7px 30px;
}

.css-1s2u09g-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #efefef !important;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.add-propertie-form-box {
  position: relative;
  width: 70%;
  padding: 40px;
  margin: 70px auto;
  background: #fbfbfb;
  border-radius: 20px;
  box-shadow: 0px 0px 4px 0px #0000003b;
}

a#usermenubutton.nav-link:hover,
.navbar .navbar-nav .nav-item .nav-link:hover {
  color: #6a1f15 !important;
  background: none !important;
}

a#usermenubutton.nav-link i {
  margin: 0 10px 0 0;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  top: 35px;
  left: -125px;
  display: inline-block;
  width: 250px;
}

.property_listing.property_card_default {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  margin: 20px;
  box-shadow: 0 -1px 19px 0 #00000029;
  transition: all 0.3s;
}

.section-standard .property_listing.property_card_default {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  margin: 20px 5px !important;
  box-shadow: 0 -1px 19px 0 #00000029;
  transition: all 0.3s;
}

.property_listing.property_card_default:hover {
  transform: translateY(-10px);
  box-shadow: 1px 3px 20px 9px #00000029;
}

.property_listing_img_section {
  position: relative;
}

.property_listing_img img {
  width: 100%;
}

.property_listing_img {
  width: auto;
  height: 263px;
  overflow: hidden;
}

.property_listing_img_header {
  position: absolute;
  top: 15px;
  padding: 20px;
  width: 100%;
}

.featured_div {
  max-width: 85px;
  float: left;
  background-color: #f1bf7f;
  background-color: rgb(215 63 42);
  color: #fff;
  position: relative;
  font-size: 11px;
  padding: 0px 15px;
  line-height: 20px;
  background-image: none !important;
  margin-bottom: 4px;
  top: 0;
  font-weight: 600;
  border-radius: 2px;
}

.propertie-type-wrapper {
  font-size: 11px;
  float: right;
  background-color: rgba(36, 42, 58, 0.91);
  color: #fff;
  position: relative;
  padding: 0px 15px;
  top: 0;
  text-align: center;
  line-height: 20px;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 10%);
  border-radius: 2px;
}

.propertie-projet-wrapper {
  font-size: 11px;
  float: right;
  background-color: rgb(250 203 1);
  color: #fff;
  position: relative;
  padding: 0px 15px;
  margin-top: 5px;
  top: 0;
  clear: both;
  text-align: center;
  line-height: 20px;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 10%);
  border-radius: 2px;
}

.property_listing_img_bottom {
  position: absolute;
  bottom: 0px;
  padding: 20px;
  width: 100%;
  z-index: 2;
}

.property_listing_img_bottom i {
  font-weight: 700;
  font-size: 14px;
}

.property_listing_img_bottom a {
  color: #fff;
}

.property_location_image {
  float: left;
  color: #fff;
  position: relative;
  font-size: 11px;
  line-height: 20px;
  top: 0;
  font-weight: 600;
}

.property_media {
  float: right;
  color: #fff;
  position: relative;
  font-size: 11px;
  line-height: 20px;
  top: 0;
  font-weight: 600;
}

.property_listing {
  position: relative;
  height: 100%;
  border: 1px solid #f1f8ff;
  font-size: 13px;
  background-color: #ffffff;
  max-width: 525px;
  transition: 0.3s ease-out;
  float: left;
}

.property_listing:hover {
  transform: translate(0%, -10px);
}

/* .kylomo-section.section-property-type {
  background: #f1f1f1;
  padding: 70px 0;
} */

.property_listing.places_listing {
  min-height: 400px;
  width: 100%;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 31px 0 rgb(7 152 255 / 9%);
}

.property_listing h4 {
  padding: 0px 20px;
  margin-top: 15px;
  margin-bottom: 0px;
  float: left;
  width: 100%;
  font-weight: 800;
}

.property_listing.places_listing h4 a {
  display: inline-block;
  background: #242a3a;
  font-size: 16px;
  font-weight: 800;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}

.property_location {
  position: absolute;
  bottom: 0px;
  left: 20px;
  right: 20px;
  height: auto;
  border-top: 1px solid #eef3f6;
  font-size: 13px;
  margin: 0px 0px 0px 0px;
  padding: 13px 0px;
  color: #8593a9;
  line-height: 16px;
  float: left;
  max-width: 100%;
  color: #f7f7f7;
  line-height: 30px;
  font-size: 15px;
  font-weight: 700;
  border: 0px;
}

.featured_gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.67;
  z-index: 1;
  background-image: linear-gradient(to right top, #000, rgba(0, 0, 0, 0) 56%);
  /* background-image: linear-gradient( to top, #000, rgba(0,0,0, 0) 56%); */
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0) 56%
  );
  pointer-events: none;
}

.property_content {
  position: relative;
  padding: 20px;
}

.property_content h4 a {
  font-size: 20px;
  color: #000;
}

.listing_unit_price_wrapper {
  font-size: 18px;
  color: #d73f2a;
  font-weight: 700;
}

.slick-prev:before {
  content: '\2190' !important;
}

.slick-next:before,
.slick-prev:before {
  font-family: slick;
  font-size: 30px;
  line-height: 1;
  opacity: 0.75;
  color: #d73f2a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2.section-title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}

.kylomo-section {
  margin: 70px 0 100px;
}

.call-to-action {
  background: #d73f2a;
  padding: 60px;
  margin: 30px 0;
}

.call-to-action-text h2 {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
}

.call-to-action-text p {
  color: #fff;
  font-size: 21px;
}

.call-to-action-text {
  display: inline-block;
  width: 80%;
}

.call-to-action-button {
  display: inline-block;
  width: 30%;
}

.annonce-card .annonce-text-section {
  display: inline-block;
  width: 50%;
  padding: 50px;
  min-height: 836px;
}

.annonce-card .form-section {
  display: inline-block;
  width: 50%;
  float: right;
  background: #fff;
  padding: 40px;
}

.not-found-box {
  margin: 100px;
}

.not-found-box img {
  width: 130px;
}

.side-bar-left {
  padding: 60px 20px 20px;
  height: 100%;
  background: #fff;
  border-right: 1px solid #e4e4e4;
}

.side-bar-left .current-user-box {
  text-align: center;
}

.side-bar-left .current-user-box img {
  width: 60px;
  border-radius: 50%;
  box-shadow: 0px 3px 23px #e3e6ec;
  margin-bottom: 20px;
}

.side-bar-left ul.main-menu {
  position: relative;
  list-style: none;
  padding: 0;
  margin-top: 50px;
}

.side-bar-left ul.main-menu li {
  display: block;
  margin: 10px;
}
.side-bar-left ul.main-menu li ul li {
  display: block;
  margin: 5px;
}

.side-bar-left ul.main-menu li a {
  color: #808191;
  width: 100%;
  padding: 15px 15px;
  margin: 0px;
  text-transform: capitalize;
  font-weight: 500;
  position: relative;
  font-size: 16px;
  transition: none;
  line-height: 22px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  transition: all 0.3s;
}

.side-bar-left ul.main-menu li ul li a {
  color: #808191;
  width: 100%;
  padding: 10px;
  margin: 0px;
  text-transform: capitalize;
  font-weight: 500;
  position: relative;
  font-size: 16px;
  transition: none;
  line-height: 22px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  transition: all 0.3s;
}

.side-bar-left ul li a.active,
.side-bar-left ul li a:hover,
.side-bar-left ul li ul li a.active,
.side-bar-left ul li ul li a:hover {
  background: #d73f2a;
  color: #fff;
  border-radius: 20px;
  text-decoration: none;
}

.side-bar-left ul li a i {
  margin-right: 10px;
  font-weight: 800;
}

.dashboard-content {
  margin: 40px 35px 20px 10px;
}

.dsh-page-title h2 {
  font-weight: 800;
}

.card.annonces-recap {
  padding: 20px;
  border-radius: 15px;
}

.bg--gray {
  background: #f3f3f3;
}

.dsh-content {
  margin-top: 40px;
}

.card.annonces-recap {
  padding: 20px;
  border-radius: 15px;
}

.main.bg--gray {
  background: #f3f3f3;
}

.card.annonces-recap ul {
  padding: 0;
  list-style: none;
}

.card.annonces-recap ul li {
  display: inline-block;
  padding: 10px;
  margin: 5px;
}

.property_dash {
  border-top: 1px solid #b19c9c;
  padding: 30px 0px 30px 0px;
  width: 100%;
}

.card.user-active-properties-box {
  padding: 20px;
  border-radius: 15px;
}

.blog_listing_image {
  float: left;
  display: flex;
  position: relative;
  align-items: center;
}

.dashbard_unit_image {
  position: relative;
  float: left;
}

.property_dash .blog_listing_image img {
  border-radius: 6px;
}
.dashbard_unit_image:after {
  content: ' ';
  position: absolute;
  height: 10px;
  width: 90%;
  background-color: #d73f2a5e;
  bottom: -7px;
  left: 5%;
  border-radius: 9px;
  z-index: 0;
}

.dashbard_unit_image img {
  position: relative;
  z-index: 1;
}

.property_dashboard_location_klm {
  float: left;
  padding-left: 10px;
  max-width: 189px;
}

.dashbard_unit_title {
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 1.1em;
  font-weight: 600;
  display: inline-block;
  color: #222;
}

.dashbard_unit_title {
  font-size: 16px !important;
  margin-bottom: 5px;
  line-height: 1.1em;
  font-weight: 600;
  display: inline-block;
  color: #222;
}

.property_dashboard_location a,
.property_dashboard_status_unit {
  font-size: 11px;
}

.property_dashboard_location {
  display: block;
}

.property_dashboard_location a {
  color: #808191;
}

.property_dashboard_status_unit {
  color: #808191;
  margin: 5px 0px 0px 0px;
  clear: both;
}

.property_dashboard_price .price_label,
.property_dashboard_price,
.property_dashboard_status,
.property_dashboard_types {
  font-size: 14px;
  color: #222;
  display: flex;
  align-items: center;
  height: 70px;
  white-space: pre-wrap;
  line-height: 1.3em;
  align-content: space-evenly;
  flex-wrap: wrap;
}

.property_list_status_label {
  font-size: 14px;
  position: relative;
  padding: 4px;
  margin: 0 30px;
  background: #d73f2e;
  color: #fff;
  border-radius: 5px;
  display: inline-block;
}

.property_dashboard_price {
  color: #d73f2a;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.b-r-20 {
  border-radius: 20px !important;
}

.b-r-10 {
  border-radius: 10px !important;
}

.search-form--box {
  background: #b13221;
  padding: 40px;
}

.annonces-recap i {
  font-size: 3rem;
}

.card-stats .numbers {
  text-align: right;
}
.card-stats .numbers p.card-category {
  margin-bottom: 0;
}
.card-stats .numbers p.card-title {
  margin-bottom: 0;
  font-size: 2em;
}

.card__o {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%);
  background-color: #ffffff;
  color: #333333;
  margin-bottom: 20px;
  transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
}

.side-bar-left.admin {
  background: #221f1f;
}

.side-bar-left.admin p {
  color: #fff;
}

.not-found {
  background: #d73f2a;
  position: relative;
  display: block;
  width: 100%;
  height: 100vh;
  padding: 200px;
  color: #fff;
}

.not-found h1 {
  font-size: 194px;
  font-weight: 900;
  color: #fff;
}

.not-found a {
  text-decoration: none;
  display: inline-block;
  padding: 10px 15px;
  font-weight: 600;
  color: #fff;
}

.not-found a:hover {
  text-decoration: none;
  display: inline-block;
  padding: 10px 15px;
  font-weight: 600;
  color: #fff;
  background: #000 !important;
}

/* propertie single page style */
.single_property_labels {
  position: relative;
  left: -5px;
  top: -10px;
}

.notice_area {
  margin-bottom: 35px;
  position: relative;
  margin-top: 30px;
}

.property_title_label {
  border-radius: 3px;
  background-color: rgb(215 63 42);
  padding: 6px 14px;
  display: inline-block;
  color: #fff;
  margin: 0px 0px 0px 7px;
}

.property_title_label a {
  color: #fff;
  font-size: 13px;
}

.entry-title {
  margin: 0px 0px 15px 0px;
  width: 100%;
  float: left;
  margin: 0 0 30px;
  font-weight: 700;
}

.entry-prop {
  margin-bottom: 0px;
  display: inline;
  float: left;
  width: auto;
  max-width: 75%;
  font-size: 38px;
  line-height: 1.1;
  letter-spacing: -1px;
}

.price_area {
  color: #facb01;
  font-size: 26px;
  padding: 0px 0px 0px 0px;
  float: right;
  font-weight: 600;
  letter-spacing: -1px;
}

.notice_area .price_label {
  font-size: 28px;
  margin-top: 0px;
}

.property_categs {
  float: left;
  width: 100%;
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 10px;
}

.property_description {
  background-color: #fff;
  padding: 30px;
  margin: 30px 0;
  width: 100%;
}

.panel-group.property-panel {
  margin-bottom: 30px;
  padding: 30px;
  display: inline-block;
  width: 100%;
  background: #fff;
  -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}

h4.panel-title {
  margin-bottom: 20px;
}
/* eof */

.property_content h4 {
  min-height: 56px;
}

.property_listing .property_content h4 {
  padding: 0px 20px 0 0;
  margin-top: 15px;
  margin-bottom: 0px;
  float: left;
  width: 100%;
  font-weight: 800;
}

.admin-dashboard .side-bar-left {
  padding: 60px 0;
  height: 100%;
  border-right: 1px solid #e4e4e4;
}

.admin-dashboard .side-bar-left ul.main-menu li a {
  color: #fff;
  width: 100%;
  padding: 15px 10px;
  margin: 0;
  text-transform: capitalize;
  font-weight: 500;
  position: relative;
  font-size: 16px;
  transition: none;
  line-height: 22px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  transition: all 0.3s;
}

.admin-dashboard .side-bar-left ul.main-menu li {
  display: block;
  margin: 0;
  padding: 0;
}

.admin-dashboard .collapse.show ul {
  padding: 0;
}

.admin-dashboard .collapse.show {
  background: #3a3736;
}

.admin-dashboard a.active,
.admin-dashboard .side-bar-left ul li a:hover,
.admin-dashboard .side-bar-left ul li ul li a.active,
.admin-dashboard .side-bar-left ul li ul li a:hover {
  background: #d73f2a;
  color: #fff;
  border-radius: 0px;
  text-decoration: none;
}

.admin-dashboard .side-bar-left ul.main-menu li a {
  color: #fff;
  font-size: 14px;
  border-radius: 0px;
  transition: all 0.3s;
}

.admin-dashboard .side-bar-left ul.main-menu li ul li a {
  color: #fff;
  width: 100%;
  padding: 10px;
  margin: 0px;
  text-transform: capitalize;
  font-weight: 500;
  position: relative;
  font-size: 14px;
  transition: none;
  line-height: 22px;
  display: flex;
  align-items: center;
  border-radius: 0px;
  transition: all 0.3s;
}

.search-form--box h2.section-title {
  color: #fff;
  width: 60%;
}

.content-loading {
  min-height: 350px;
}

.content-loading img.submit-loader {
  position: relative;
  width: 200px;
  padding: 50px 0;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.propertie-type-wrapper a,
.propertie-type-wrapper a:hover {
  color: #fff;
  text-decoration: none;
}

.page-carousel.card {
  margin-top: 20px;
}

.carousel-item img {
  height: auto;
  width: 100%;
  border-radius: 0px !important;
}

.carousel-inner {
  padding: 0 100px;
  background: #302d2d;
}
.section-box {
  background-color: #fff;
  padding: 30px;
  margin: 30px 0;
  width: 100%;
  min-height: 100px;
}

.panel-user-name-box img {
  width: 60px;
  border-radius: 50%;
  box-shadow: 0px 3px 23px #e3e6ec;
  margin-bottom: 20px;
  float: left;
}

.panel-user-name-box p {
  margin: 0 10px;
  float: left;
}

.panel-user-name-box img {
  width: 60px;
  border-radius: 50%;
  box-shadow: 0px 3px 23px #e3e6ec;
  margin-bottom: 20px;
  float: left;
}

.panel-user-name-box p {
  margin: 0 10px;
  float: left;
}

div#property_user_section {
}

.agent_contact_form {
  clear: both;
}

.schedule_meeting {
  width: 100%;
  margin-left: 0px;
  padding-left: 10px;
  margin-top: 10px;
  background-color: #0abff7;
  padding: 3px 15px 3px;
  color: #fff;
  cursor: pointer;
  font-size: 10px;
}

.agent_contact_form .show_contact {
  font-size: 16px;
}

.m-r-20 {
  margin-right: 20px;
}
footer.footer.footer-black.footer-black {
  background: #272727;
}
